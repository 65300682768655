import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [3,5];

export const dictionary = {
		"/": [~6],
		"/admin/campaigns": [10,[3]],
		"/admin/campaigns/[id]": [~11,[3]],
		"/admin/campaigns/[id]/pay": [~12,[3]],
		"/admin/campaigns/[id]/pay/sucess": [~13,[3]],
		"/admin/contributors": [~14,[3]],
		"/admin/settings/payment-settings": [~15,[3,4]],
		"/admin/settings/profile": [~16,[3,4]],
		"/auth/sigin": [17,[5]],
		"/auth/signup": [18,[5]],
		"/contato": [19],
		"/knowledge-base/[articleId]": [20],
		"/terms-of-use": [21],
		"/[riffleId]": [~7,[],[2]],
		"/[riffleId]/[bookId]/pay": [8,[],[2]],
		"/[riffleId]/[bookId]/pay/sucess": [9,[],[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';